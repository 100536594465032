/**
 * useAudioPlayerBottomPadding Composable
 *
 * Purpose:
 * This composable provides a dynamic calculation of the bottom padding for an audio player component.
 * It determines the padding based on the presence of a current audio track and the visibility setting of the audio player.
 *
 * Returns:
 * - audioPlayerBottomPadding: A computed property that holds the CSS utility class value for the bottom padding.
 *
 * Usage Example:
 * ```javascript
 * const { audioPlayerBottomPadding } = useAudioPlayerBottomPadding();
 * ```
 */

import { useAudioPlayerNewStore } from '@/stores/audioPlayerNew'

export function useAudioPlayerBottomPadding() {
  const audioPlayerNewStore = useAudioPlayerNewStore()

  // Computed property to determine the bottom padding for the audio player
  const audioPlayerBottomPadding = computed(() => {
    if (
      Object.keys(audioPlayerNewStore.currentAudio).length !== 0
      && audioPlayerNewStore.settings.hidePlayer === false
    ) { return 'bottom-[70px] md:bottom-[90px]' }
    else { return 'bottom-0' }
  })

  // Expose the computed property for consumption in components
  return { audioPlayerBottomPadding }
}
